<template>
	<div class="mx-5">
		<!-- This example requires Tailwind CSS v2.0+ -->
		<div class="container flex flex-col m-auto">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<h3 class="mt-10 text-lg font-medium text-gray-900 leading-6">
						{{ $i18n("Feeds") }} {{ userId ? ' - ' + $i18n("User") + ' - ' + userEmail : '' }}
					</h3>
					<DataFilter>
						<template #create>
							<router-link
								:to="{ name: 'Feed', params: { method: 'create', userId } }"
								class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-l-0 border-transparent rounded rounded-l-none shadow-sm rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
							>
								{{ $i18n("Create") }}
							</router-link>
						</template>
					</DataFilter>
					<Loader v-if="feedsLoad" />
					<div v-if="!feedsLoad && feeds.data.length" class="flex">
						<t-pagination
							v-model="currentPage"
							:per-page="feedsLimit"
							:total-items="feeds.meta.results_count"
							variant="rounded"
							class="mx-auto mb-5"
						/>
					</div>
					<div
						v-if="!feedsLoad"
						class="border-b border-gray-200 shadow sm:rounded-lg"
					>
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-gray-50">
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Name") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Created at") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Updated at") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Last shown") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Posts") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Sources count") }}
									</th>
									<th scope="col" class="relative px-6 py-3"></th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr
									v-for="{
										id,
										name,
										created_at,
										updated_at,
										last_shown,
										posts_count,
										posts_count_24h,
										sources_count,
									} in feeds.data"
									:key="id"
								>
									<td class="py-4 pr-6">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500 break-all">
													<router-link
														:to="{ path: `feed/view/${id}`, hash: undefined }"
														class="text-gray-500 underline hover:text-gray-900"
													>
														{{ name }}
													</router-link>
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ formatDate({ date: created_at }) }}
												</div>
											</div>
										</div>
									</td>

									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ formatDate({ date: updated_at }) }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ formatDate({ date: last_shown }) }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ posts_count }} {{ posts_count ? `(${posts_count_24h})` : "" }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ sources_count ? sources_count : "" }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 text-sm font-medium text-right whitespace-nowrap">
										<router-link
											:to="{ name: 'Sources', params: { feedId: id } }"
											class="mr-5 text-blue-500 hover:text-blue-900"
										>
											{{ $i18n("Update") }} {{ $i18n("sources") }}
										</router-link>
										<router-link
											:to="{ path: `feed/view/${id}`, hash: undefined }"
											class="mr-5 text-gray-500 hover:text-gray-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
												/>
											</svg>
										</router-link>
										<router-link
											:to="{ path: `feed/update/${id}` }"
											class="mr-5 text-blue-600 hover:text-blue-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
												/>
											</svg>
										</router-link>
										<div
											@click="deleteFeedByIdMethod({ id, name })"
											class="inline text-red-600 cursor-pointer hover:text-red-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
												/>
											</svg>
										</div>
									</td>
								</tr>

								<!-- More feed... -->
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<t-pagination
				v-if="!feedsLoad && feeds.data.length"
				v-model="currentPage"
				:per-page="feedsLimit"
				:total-items="feeds.meta.results_count"
				variant="rounded"
				class="mt-5"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import DataFilter from "@/components/DataFilter";

export default {
	name: "Feeds",
	components: {
		Loader,
		DataFilter,
	},
	props: {
		userId: {
			type: String,
			default: "",
		},
		page: {
			type: String,
			default: "1",
		},
	},
	data () {
		return {
			userEmail: ""
		}
	},
	watch: {
		userId: {
			immediate: false,
			async handler (userId) {
				try {
					if (!userId) {
						await this.fetch();
						this.$forceUpdate();
					}
				} catch (error) {
					console.debug(error);
				}
			}
		}
	},
	computed: {
		...mapGetters("feeds", ["feedsLimit", "feedsLoad", "feeds"]),
		currentPage: {
			get() {
				return Number(this.page);
			},
			async set(page) {
				try {
					const { $route, $router } = this;
					const {
						$route: { params },
					} = this;
					const route = {
						...$route,
						params: { ...params, page: String(page) },
					};
					await $router.push(route);
					await this.fetch();
				} catch (error) {
					console.debug(error);
				}
			},
		},
	},
	methods: {
		...mapActions("feeds", ["fetchFeeds", "deleteFeedById"]),
		...mapActions("users", ["fetchUserById"]),
		async deleteFeedByIdMethod({ id, name }) {
			try {
				const {
					$dialog: { confirm },
					$i18n,
					$router,
					currentPage,
					fetch,
				} = this;

				const { isOk } = await confirm({
					clickToClose: false,
					escToClose: false,
					title: $i18n("Delete") + " " + name + " ?",
					icon: "info",
					cancelButtonText: $i18n("Cancel"),
					okButtonText: $i18n("Do"),
				});
				if (isOk) {
					await this.deleteFeedById({ id, name });
					const resultsCount = this.feeds.data.length;

					if (currentPage !== 1 && !resultsCount) {
						await $router.replace({
							...this.$route,
							params: { ...this.$route.params, page: this.$route.params.page - 1 },
						});
					}
					await fetch();
				}
			} catch (error) {
				console.debug(error);
			}
		},
		async fetch({ withPage = true } = {}) {
			try {
				const {
					userId,
					page,
					$route: { query },
				} = this;

				if (userId) {
					query.user_id = userId
				}

				await this.fetchFeeds({
					page: withPage ? page : undefined,
					query
				});
			} catch (error) {
				console.debug(error);
			}
		},
		formatDate({ date }) {
			const dateInstance = new Date(date);
			return (
				dateInstance.toLocaleDateString() + " " + dateInstance.toLocaleTimeString()
			);
		},
	},
	async created() {
		try {
			this.$on("Filter", async () => {
				await this.fetch({ withPage: false });
			});

			await this.fetch();
		} catch (error) {
			console.debug(error);
		}
		try {
			this.userEmail = ""
			const { userId } = this
			if (userId) {
				const { email } = await this.fetchUserById(userId);
				this.userEmail = email;
			}
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
